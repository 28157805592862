/*
* 업무구분: 고객
* 화 면 명: MSPFS107M
* 화면설명: 인출가능금액조회2510
* 작 성 일: 2023.04.10
* 작 성 자: 이태흥
* 수정일 : 2024-02-18 강현철 ASR250100285 사랑온 수익증권 외납세 개발 조회항목 개발
* 수정일 : 2024-04-08 강현철 ASR250201079 (사랑온) 마케팅동의서 화면 및 양식 수정
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40 w100p">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <mo-decimal-field
                    numeric
                    ref="ed_accn_no"
                    class="w130 input-account"
                    :class="lv_ed_accn_no.error ? 'error' : ''"
                    v-model="lv_ed_accn_no.value"
                    @keyup="fn_SearchValid($event)"
                    @paste="fn_SearchValid($event, 'P')"
                    mask="#######-###"
                  />
                  <mo-dropdown ref="ed_acct_gb" :items="lv_acct_list" v-model="lv_ed_acct_gb.value" class="w340" placeholder=" " :disabled="lv_ed_acct_gb.disabled" />
                  <mo-text-field v-model="lv_ed_cust_nm.value" class="w100" disabled />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left gap40 w100p">
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 환매구분 </label>
                  <mo-dropdown class="w120" :items="lv_cbo_redem_gb_items" v-model="lv_cbo_redem_gb.value" />
                </div>
                <div class="wrap-input row flex-unset">
                  <label> 환매신청금액(좌수) </label>
                  <mo-text-field
                    ref="bfcer_rpus_apl_amt"
                    class="w120"
                    :class="lv_bfcer_rpus_apl_amt.error ? 'error' : ''"
                    v-model="lv_bfcer_rpus_apl_amt.value"
                    maxlength="25"
                    :disabled="lv_cbo_redem_gb.value == '11'"
                    mask="number"
                  />
                </div>
                <div class="wrap-input row flex-unset" v-if="lv_next_req_dt != ''">
                  <label> 차기 정기 환매신청일 </label>
                  <mo-text-field class="w120" v-model="lv_next_req_dt" disabled />
                </div>
              </div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button class="btn-clear" @click="fn_Init()"> 초기화 </mo-button>
                  <mo-button primary class="btn-inquiry" @click="fn_DrotPsbAmtSearch()"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info ref="fsCustInfo" :propObj="pCustInfoObj" @fs-cust-name="fn_SetEdCustName"></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container>
        <div class="wrap-table-title">
          <h2 class="table-title">
            계좌기본정보
          </h2>
        </div>

        <div class="wrap-table">
          <table class="table th-wsN col-type type02 col-th110">
            <tbody>
              <tr>
                <th>펀드코드</th>
                <td colspan="5">
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.fund_cd" disabled />
                    <mo-text-field class="input-long" v-model="lv_searchInfo.fund_nm" disabled />
                  </div>
                </td>
                <th>과표상계금액</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.set_off_amt" mask="number" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>계좌번호</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.acct_no" disabled />
                  </div>
                </td>
                <th>{{ lv_tax_free_endday_text }}</th>
                <td>
                  <div class="wrap-input row" v-if="lv_tax_free_endday_text != ''">
                    <mo-text-field class="w100" v-model="lv_searchInfo.tax_free_endday" disabled />
                  </div>
                </td>
                <th>잔고좌수</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.remn_qty" mask="number" disabled />
                  </div>
                </td>
                <th>세전평가금액</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.eval_amt" mask="number" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>납입원금</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.orign_amt" mask="number" disabled />
                  </div>
                </td>
                <th>세전누적 수익률</th>
                <td>
                  <div class="wrap-input row aR">
                    <mo-text-field class="w100" v-model="lv_searchInfo.prof_rt" disabled />
                    <span>%</span>
                  </div>
                </td>
                <th>
                  인출 후<br />
                  잔고좌수
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.af_remn_qty" mask="number" disabled />
                  </div>
                </td>
                <th>미결제수표금액</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="w130" v-model="lv_searchInfo.unsettled_check_amt" mask="number" disabled />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="row gap10 w100p align-start">
          <div class="left column">
            <div class="wrap-table-title">
              <h2>인출정보</h2>
            </div>
            <div class="wrap-table">
              <table class="table th-wsN col-type type02 col-th110">
                <tbody>
                  <tr>
                    <th>인출가능좌수</th>
                    <td>
                      <mo-text-field class="w130" v-model="lv_searchInfo.paym_possqty" mask="number" disabled />
                    </td>
                    <th>기준가격</th>
                    <td>
                      <mo-text-field class="w130" v-model="lv_searchInfo.stdprc" mask="number" disabled />
                    </td>
                    <th>과표기준가격</th>
                    <td>
                      <mo-text-field class="w130" v-model="lv_searchInfo.tax_stdprc" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>인출가능금액</th>
                    <td>
                      <mo-text-field class="w130" v-model="lv_searchInfo.paym_possamt" mask="number" disabled />
                    </td>
                    <th>평가금액<br />(인출후세전)</th>
                    <td colspan="3">
                      <mo-text-field v-model="lv_searchInfo.bf_eval_amt" class="input-long" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>수수료</th>
                    <td>
                      <mo-text-field class="w130" v-model="lv_searchInfo.fee" mask="number" disabled />
                    </td>
                    <th>성과보수</th>
                    <td colspan="3">
                      <mo-text-field v-model="lv_searchInfo.outcome_rewd" class="input-long" mask="number" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right column">
            <div class="wrap-table-title">
              <h2>정기지급형정보</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col-th110">
                <tbody>
                  <tr>
                    <th>총기지급액</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.tot_redemamt" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>지급정보</th>
                    <td>
                      <mo-text-field v-model="lv_edt_pay_info" class="w130" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="row gap10 w100p align-start">
          <div class="w25p">
            <div class="wrap-table-title">
              <h2>합계 과표&amp;소득세</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col-th110">
                <tbody>
                  <tr>
                    <th>{{ lv_taxText.stdass }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.stdass" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <!-- ASR250100285 사랑온 수익증권 외납세 개발 조회항목 개발 25.02.18 -->
                  <tr>
                    <th>외납세공제</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.sbtr_frg_ptax_amt" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <!---->
                  <tr>
                    <th>{{ lv_taxText.inc_tax }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.inc_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ lv_taxText.resi_tax }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.resi_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ lv_taxText.farm_tax }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.farm_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w25p">
            <div class="wrap-table-title">
              <h2>거래분 과표&amp;소득세</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col-th110">
                <tbody>
                  <tr>
                    <th>거래분과표</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.busi_stdass" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>거래분소득세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.busi_inc_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>거래분지방소득세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.busi_resi_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>거래분농특세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.busi_farm_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w25p">
            <div class="wrap-table-title">
              <h2>환급 과표&amp;소득세</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col-th110">
                <tbody>
                  <tr>
                    <th>환급과표</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.repay_stdass" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>환급소득세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.repay_inc_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>환급지방소득세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.repay_resi_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>환급농특세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.repay_farm_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w25p">
            <div class="wrap-table-title">
              <h2>추징 과표&amp;소득세</h2>
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col-th110">
                <tbody>
                  <tr>
                    <th>{{ lv_taxText.levy_stdass }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.levy_stdass" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ lv_taxText.levy_inc_tax }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.levy_inc_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ lv_taxText.levy_resi_tax }}</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.levy_resi_tax" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                  <tr>
                    <th>추징유보세</th>
                    <td>
                      <mo-text-field v-model="lv_searchInfo.recv_fee" class="w130" mask="number" disabled />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="right-bg-white mt20" v-if="lv_isList">
          <div class="wrap-table x-scroll h-scroll">
            <table class="table row-type">
              <thead>
                <tr>
                  <th>입금일</th>
                  <th>매입구분</th>
                  <th>입금좌수</th>
                  <th>입금기준가</th>
                  <th>입금과표가</th>
                  <th>평가금액</th>
                  <th>인출가능좌수</th>
                  <th>인출예상금액</th>
                  <th>과표</th>
                  <th>유보과표</th>
                  <th>환매수수료<br />징수구간</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in lv_searchList" :key="i">
                  <td>{{ row.recp_dt }}</td>
                  <td>{{ row.recp_gb_text }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.recp_qty) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.recp_stdprc) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.recp_tax_stdprc) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.eval_amt) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.paym_possqty) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.paym_possamt) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.stdass) }}</td>
                  <td class="aR">{{ $bizUtil.numberWithCommas(row.resv_remn_tax) }}</td>
                  <td>{{ row.redem_fee_yn }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS107M',
  screenId: 'MSPFS107M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
  },
  mounted() {
    this.fn_Init()

    this.$bizUtil.insSrnLog('MSPFS107M')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '인출가능금액조회',
        step: ''
      },
      pAlertPopupObj: {},
      lv_commCode: {},
      pCustInfoObj: {},

      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,

      // 검색조건
      lv_ed_accn_no: {}, // 계좌번호
      lv_ed_acct_gb: {}, // 계좌종류
      lv_ed_cust_nm: {}, // 고객명
      lv_cbo_redem_gb: {}, // 환매구분
      lv_cbo_redem_gb_items: [], // 환매구분 list
      lv_bfcer_rpus_apl_amt: {}, // 환매신청금액
      lv_next_req_dt: '', // 차기 정기 환매신청일

      // 계좌정보
      lv_cust_info: {}, // 고객정보
      lv_acct_list: [], // 계좌조회목록

      // 검색결과
      lv_searchInfo: {},
      lv_searchList: {},
      lv_isList: true,
      lv_edt_pay_info: '', // 지급정보

      // 비과세/소득공제 일몰일 TEXT
      lv_tax_free_endday_text: '',

      // 과표&소득세 TEXT
      lv_taxText: {},

      // 고객정보 펼치기/접기
      isActive: false,

      /***********************************************************************************
       * F10570155 : 인출가능금액조회
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 계좌목록 선택
    'lv_ed_acct_gb.value': {
      handler(after, before) {
        // 조회결과 초기화
        this.lv_searchInfo = {}
        this.lv_searchList = []
        this.fn_SetTaxText()
        this.lv_tax_free_endday_text = ''

        if (this.lv_acct_list.length > 0) {
          this.fn_SetTaxText(this.lv_acct_list[after].taxsyTypScCd)
          this.fn_SetCboRedemGb(this.lv_acct_list[after].hdgeFndYn)
        }
      },
      deep: true
    },

    // 환매구분 선택
    'lv_cbo_redem_gb.value': {
      handler(after, before) {
        this.lv_bfcer_rpus_apl_amt.error = false

        if (after == '11') {
          this.lv_bfcer_rpus_apl_amt.value = ''
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        { cId: '303', dayChkYn: 'N' },
        { cId: '322', dayChkYn: 'N' }
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      let t_codeList = []
      pResult.code322.forEach((item) => {
        if (item.value == '11' || item.value == '12' || item.value == '13') {
          t_codeList.push(item)
        }
      })

      // 환매구분 list
      this.lv_commCode.code322 = t_codeList
      this.lv_cbo_redem_gb_items = this.lv_commCode.code322
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS107M] fn_Init...')

      // 검색조건
      this.lv_ed_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no') // 계좌번호
      this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb') // 계좌종류
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1) // 고객명
      this.lv_cbo_redem_gb = FSCommUtil.gfn_dataSet(0, '11') // 환매구분
      this.lv_cbo_redem_gb_items = this.lv_commCode.code322 // 환매구분 list
      this.lv_bfcer_rpus_apl_amt = FSCommUtil.gfn_dataSet(1, '', false, '', 'bfcer_rpus_apl_amt') // 환매신청금액
      this.lv_next_req_dt = '' // 차기 정기 환매신청일

      // 계좌정보
      this.lv_acct_list = [] // 계좌조회목록

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init()

      // 검색결과
      this.lv_searchInfo = {}
      this.lv_searchList = []
      this.lv_isList = true

      // 지급정보
      this.lv_edt_pay_info = ''

      // 비과세/소득공제 일몰일 TEXT
      this.lv_tax_free_endday_text = ''

      // 과표&소득세 TEXT
      this.lv_taxText = {
        stdass: '과표',
        inc_tax: '소득세',
        resi_tax: '지방소득세',
        farm_tax: '농특세',
        levy_stdass: '추징과표',
        levy_inc_tax: '추징소득세',
        levy_resi_tax: '추징지방소득세'
      }
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/
    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive
    },

    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')

      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.lv_ed_cust_nm.value = val
    },

    /******************************************************************************
     * Function명 : fn_AccnNoList, fn_AccnNoListResult
     * 설명       : 계좌목록조회
     ******************************************************************************/
    fn_AccnNoList(pAccnNo) {
      console.log('fn_AccnNoList.....')

      let t_data = { bfcer_cstgr_no: pAccnNo }
      FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
    },

    fn_AccnNoListResult(pResultData) {
      console.log('fn_AccnNoListResult.....')

      let t_data = pResultData.data.sy000000_O_00VO
      console.log(t_data)

      // 계좌목록조회 초기화
      this.lv_acct_list = []
      this.lv_ed_acct_gb.disabled = true

      if (t_data.length > 0) {
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({
            value: idx,
            text: item.acct_no_nm,
            hiddenValue: item.bfcer_accn_no,
            taxsyTypScCd: item.bfcer_taxsy_typ_sc_cd,
            hdgeFndYn: item.hdge_fnd_yn
          })
        })

        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0
        this.lv_ed_acct_gb.disabled = false

        // 조회결과 초기화
        this.lv_searchInfo = {}
        this.lv_searchList = []
        this.fn_SetTaxText()
        this.lv_next_req_dt = ''

        // 고객정보조회
        this.fn_CustInfo()
      } else {
        let t_popupObj = { content: '검색할 계좌가 없습니다.' }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCboRedemGb
     * 설명       : hdge_fnd_yn 값에 따라 환매구분 세팅
     ******************************************************************************/
    fn_SetCboRedemGb(pType) {
      console.log('fn_SetCboRedemGb.....')
      let t_popupObj = {}

      if (pType == 'Y') {
        t_popupObj.content = '성과보수발생펀드 입니다. 전액/좌수 환매만 가능합니다.'
        this.fn_AlertPopup(0, t_popupObj)

        this.lv_cbo_redem_gb_items = [
          { value: '11', text: '전액환매' },
          { value: '12', text: '좌수환매' }
        ]
      } else {
        this.lv_cbo_redem_gb_items = this.lv_commCode.code322
      }
    },

    /******************************************************************************
     * Function명 : fn_SetTaxText
     * 설명       : tax_tp_gb 값에 따라 대상 Static의 Text를 변경하는 함수(Expr 전용)
     ******************************************************************************/
    fn_SetTaxText(pType) {
      console.log('fn_SetTaxText.....')

      if (pType == '7') {
        this.lv_taxText = {
          tax_tp_gb: pType,
          stdass: '기타소득',
          inc_tax: '기타소득세',
          resi_tax: '기타지방소득세',
          farm_tax: '기타농특세',
          levy_stdass: '해지가산과표',
          levy_inc_tax: '해지소득세',
          levy_resi_tax: '해지지방소득세'
        }
      } else {
        this.lv_taxText = {
          tax_tp_gb: pType,
          stdass: '과표',
          inc_tax: '소득세',
          resi_tax: '지방소득세',
          farm_tax: '농특세',
          levy_stdass: '추징과표',
          levy_inc_tax: '추징소득세',
          levy_resi_tax: '추징지방소득세'
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_DrotPsbAmtSearch, fn_DrotPsbAmtSearchResult
     * 설명       : 인출가능금액조회
     ******************************************************************************/
    fn_DrotPsbAmtSearch() {
      console.log('fn_DrotPsbAmtSearch.....')

      this.lv_ed_accn_no.error = false

      let t_accn_no = this.lv_ed_accn_no.value ? this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0, 10) : ''

      if (FSCommUtil.gfn_isNull(t_accn_no)) {
        FSCommUtil.gfn_validate(this, '계좌번호를 입력해 주십시오.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      } else if (FSCommUtil.gfn_length(t_accn_no) != 10 || !FSCommUtil.gfn_isNum(t_accn_no)) {
        FSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        return
      } else if (this.lv_acct_list.length == 0) {
        FSCommUtil.gfn_validate(this, '검색할 계좌가 없습니다.')
        return
      }

      if (!FSCommUtil.gfn_isNull(this.lv_bfcer_rpus_apl_amt.value) && !FSCommUtil.gfn_isNum(this.lv_bfcer_rpus_apl_amt.value)) {
        FSCommUtil.gfn_validate(this, '환매신청금액(좌수) 형식에 맞지 않습니다.')
        this.lv_bfcer_rpus_apl_amt.error = true
        this.$refs['bfcer_rpus_apl_amt'].focus()
        return
      }

      if (this.lv_cbo_redem_gb.value != '11' && FSCommUtil.gfn_isNull_replace(this.lv_bfcer_rpus_apl_amt.value, 0) == 0) {
        FSCommUtil.gfn_validate(this, '환매신청금액(좌수) 입력오류입니다.')
        this.lv_bfcer_rpus_apl_amt.error = true
        this.$refs['bfcer_rpus_apl_amt'].focus()
        return
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570155_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, // 계좌번호
        bfcer_rpus_sc_cd: this.lv_cbo_redem_gb.value, // 환매구분코드
        bfcer_rpus_apl_amt: FSCommUtil.gfn_isNull_replace(this.lv_bfcer_rpus_apl_amt.value, 0), // 환매신청금액
        comp_cd: '00001', // 회사코드
        tr_code: 'RP2510'
        // secure_passv        : '',
        // media               : 'I',
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_DrotPsbAmtSearchResult)
    },

    fn_DrotPsbAmtSearchResult(pResultData) {
      console.log('fn_DrotPsbAmtSearchResult.....')

      let t_popupObj = {}
      let t_data = pResultData.data
      console.log(t_data)

      if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.error_msg))) {
        let sTaxTpGbCd = t_data.tax_tp_gb_cd
        let vPay_term = t_data.pay_term
        let vCnt_setl_yn = t_data.cnt_setl_yn
        let vFund_cd = t_data.fund_cd

        if (sTaxTpGbCd == '1') {
          // 차기 정기 환매신청일
          let t_yyyy = FSCommUtil.gfn_substr(t_data.next_req_dt, 0, 4)
          let t_mm = FSCommUtil.gfn_substr(t_data.next_req_dt, 4, 2)
          let t_dd = FSCommUtil.gfn_substr(t_data.next_req_dt, 6, 2)
          this.lv_next_req_dt = t_yyyy + '년 ' + t_mm + '월 ' + t_dd + '일'

          if (vPay_term > 1) {
            this.lv_edt_pay_info = vCnt_setl_yn + '/' + vPay_term + '회 지급' // 지급정보
          } else {
            this.lv_edt_pay_info = vCnt_setl_yn + '회 지급' // 지급정보
          }
        } else {
          this.lv_edt_pay_info = '' // 지급정보
        }

        if (sTaxTpGbCd == '8' || sTaxTpGbCd == '9') {
          //소장펀드 추가 2014.02.26 PJH
          this.lv_tax_free_endday_text = '비과세 일몰일'
        }

        if (sTaxTpGbCd == '15') {
          //소장펀드 추가 2014.02.26 PJH
          this.lv_tax_free_endday_text = '소득공제 일몰일'
        }

        /*-----------------------------
          환매수수료-일경우 0세팅
          -----------------------------*/
        // var vFee = this.ds_rp251000_o_01vo.getColumn(0, "fee");
        // if (this.gfn_isNull(vFee)) vFee = 0;
        // vFee = nexacro.toNumber(vFee);
        // if (vFee < 0) this.div_detail.div_redemInfo.mae_fee.set_value(0); //trace("환매수수료-일경우0으로세팅!");

        // 예수금계좌는 입금내역이 필요없음
        if (vFund_cd == '100000') {
          this.lv_isList = false
        } else {
          this.lv_isList = true
        }
        ////////////////////////////////////////////// AS -IS END //////////////////////////

        this.lv_searchInfo = t_data
        this.lv_searchList = t_data.rp251000_O_02VO

        // 비과세/소득공제 일몰일 치환
        if (!FSCommUtil.gfn_isNull(this.lv_searchInfo.tax_free_endday)) {
          this.lv_searchInfo.tax_free_endday = FSCommUtil.gfn_dateReplace(1, this.lv_searchInfo.tax_free_endday)
        }

        // 계좌번호 치환
        if (!FSCommUtil.gfn_isNull(this.lv_searchInfo.acct_no)) {
          let t_acct_no = this.lv_searchInfo.acct_no
          // this.lv_searchInfo.acct_no = FSCommUtil.gfn_substr(t_acct_no, 0, 7) + '-' + FSCommUtil.gfn_substr(t_acct_no, 7, 3) + '-' + FSCommUtil.gfn_substr(t_acct_no, 10, 3)
          // 계좌번호 마스킹 (4 ~ 8 자리 마스킹) 2023.08.28 정지윤
          this.lv_searchInfo.acct_no = FSCommUtil.gfn_substr(t_acct_no, 0, 3) + '****-*' + FSCommUtil.gfn_substr(t_acct_no, 8, 2) + '-' + FSCommUtil.gfn_substr(t_acct_no, 10, 3)
        }

        // 세전누적 수익률 치환
        if (!FSCommUtil.gfn_isNull(this.lv_searchInfo.prof_rt)) {
          if (Number(this.lv_searchInfo.prof_rt) == 0) {
            this.lv_searchInfo.prof_rt = Number(this.lv_searchInfo.prof_rt).toFixed(4)
          }
        }

        // LIST 정보 치환
        if (this.lv_searchList.length > 0) {
          this.lv_searchList.forEach((item1, idx) => {
            this.lv_searchList[idx].recp_dt = FSCommUtil.gfn_dateReplace(1, item1.recp_dt)

            this.lv_commCode.code303.forEach((item2) => {
              if (item1.recp_gb == item2.value) {
                this.lv_searchList[idx].recp_gb_text = item2.text
              }
            })
          })
        }
      } else {
        /*let t_valList = []
        if (t_data.error_msg == '809996') {
          t_valList.push('펀드기준가내역(TFOP01330)')
        } else {
          t_valList.push('지급예상금액(RP2510)')
        }*/

        // t_popupObj.content = FSMessageUtil.commMessage([], t_data.error_msg)
        t_popupObj.content = FSMessageUtil.commMessage([], t_data.error_msg)[t_data.error_msg.substring(0, 6)]
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event, flag) {
      console.log('fn_SearchValid.....')

      let t_accn_no = this.lv_ed_accn_no.value
      // if( flag == 'P' ) {
      //   t_accn_no = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,10)
      // } else {
      //   if( !FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value) ) {
      //     t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '').substr(0,10)
      //   }
      // }

      if (FSCommUtil.gfn_length(t_accn_no) >= 10) {
        this.lv_ed_accn_no.error = false

        if (!FSCommUtil.gfn_isNum(t_accn_no)) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        } else {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile) {
            // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

            // 계좌목록조회
            this.fn_AccnNoList(t_accn_no)
          }
        }
      } else if (t_accn_no.length > 7 || event.key == 'Delete' || event.key == 'Backspace') {
        // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

        // 계좌목록/고객명 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true
        this.lv_ed_cust_nm.value = ''

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()
      }
    }
  }
}
</script>
<style scoped></style>
